import { useEffect } from 'react';
import { restClient } from '@/config/rest-client';
import PubNub from 'pubnub';
import { ChatSettingResponse } from './pubnub.types';
import { notifyBugsnagError } from './notifyBugsnagError';
import { usePubNubStore } from '../../store/usePubNubStore';

export const usePubnubAuth = (CSRFToken: string | null) => {
  const [setChatAuthKey, chatAuthKeyExpiresIn, client]: [
    (key: string, expiresIn: number) => void,
    number | null,
    client: PubNub | null
  ] = usePubNubStore(state => [
    state.setChatAuthKey,
    state.chatAuthKeyExpiresIn,
    state.client,
  ]);

  const refreshChatAuthKey = () => {
    restClient()
      .get('/api/v1/chat.json', { headers: { 'X-Csrf-Token': CSRFToken } })
      .then(({ data }: { data: ChatSettingResponse }) => {
        setChatAuthKey(data.chat_auth_key, data.chat_access_token_expires_in);
        client?.setToken(data.chat_auth_key);
      })
      .catch((e: unknown) => notifyBugsnagError(e));
  };

  useEffect(() => {
    if (!chatAuthKeyExpiresIn || chatAuthKeyExpiresIn <= 0) return;
    const timeoutId = setTimeout(refreshChatAuthKey, chatAuthKeyExpiresIn);
    return () => clearTimeout(timeoutId);
  }, [chatAuthKeyExpiresIn]);

  return null;
};
