import { create } from 'zustand';
import PubNub from 'pubnub';
import { LastMessage, LastMessagesMap } from '@/utils/pubnub/pubnub.types';

export interface PubNubStore {
  client: PubNub | null;
  chatAuthKey: string | null;
  chatAuthKeyExpiresIn: number | null;
  setChatAuthKey: (key: string, expiresIn: number) => void;
  setClient: (value: PubNub) => void;
  notificationChannelName: string;
  setNotificationChannelName: (value: string) => void;
  lastMessagesMap: LastMessagesMap | null;
  setLastMessagesMap: (value: LastMessagesMap) => void;
  partialUpdateLastMessagesMap: (
    key: string,
    value: Partial<LastMessage>
  ) => void;
}

export const usePubNubStore = create<PubNubStore>(set => ({
  notificationChannelName: '',
  chatAuthKey: null,
  chatAuthKeyExpiresIn: null,
  setChatAuthKey: (chatAuthKey, chatAuthKeyExpiresIn) =>
    set({ chatAuthKey, chatAuthKeyExpiresIn }),
  setNotificationChannelName: (value: string) =>
    set({ notificationChannelName: value }),
  client: null,
  setClient: (value: PubNub) => set({ client: value }),
  lastMessagesMap: null,
  setLastMessagesMap: (value: LastMessagesMap) =>
    set({ lastMessagesMap: value }),
  partialUpdateLastMessagesMap: (key: string, value: Partial<LastMessage>) =>
    set(state => {
      const prev = state.lastMessagesMap;
      return {
        lastMessagesMap: {
          ...prev,
          ...(prev && {
            [key]: { ...prev[key], ...value },
          }),
        },
      };
    }),
}));
